nav{
    height:5rem;
    width:100%;
    display:grid;
    place-items: center;
    position:fixed;
    top:0;
    left:0;
    z-index:99;
    background:rgba(255,255,255,0.1);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255,255,255,0.05);
}

.nav_container{
    height:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position:relative;
}

.logo{
    width:10rem;
    
}

.nav_links{
    display: flex;
    gap:3rem;
    align-items: center;
}
.nav_links a:hover{
    color: var(--menta);
}
