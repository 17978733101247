.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    margin: 0 auto;
    min-height: 100vh;
  }
  
  .challenge {
    display: flex;
    flex-direction: row;
    padding: 6rem;
    
  }
  
  .left-Container,
  .right-Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index:2;
  }
  
  .left-Container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-Wrapper {
    width: 35em;
    height: 35em;
  }
  
  .image-Wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .right-Container {
    padding-top: 125px;
    color: white;
    font-size: 1.2rem;
    justify-content: center;
  }
  
  .learnbottom {
    color: var(--deep-marine);
    background: #48D5D6;
    padding: 10px 20px;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 2rem;
  }
  
  .peces-right {
    position: absolute;
    top:20%;
    right:0;
    animation:peces-right 7s linear infinite;
  }

  .peces-right img{
    width: 250px;
    z-index:1;
  }

@keyframes peces-right {
    0%{
        transform: translateX(0);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
        transform: translateX(-2100px);
        opacity: 1;
    }
}

@keyframes peces-left {
    0%{
        transform: translateX(0);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
        transform: translateX(2100px);
        opacity: 1;
    }
}

  .mision {
    display: flex;
    padding: 0 6rem 0 6rem;
    z-index: 2;
  }
  
  .left-ContainerM,
  .right-ContainerM {
    width: 50%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .right-ContainerM {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-WrapperM img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
    border: 5px solid;
  }
  
  .image-WrapperM {
    width: 35em;
    height: 15em;
  }
  
  .left-ContainerM {
    padding-top: 25px;
    padding-right: 20px;
    color: white;
    font-size: 1.2rem;
    justify-content: center;
  }
  
  .vision {
    display: flex;
    padding-top: 50px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
  
  .left-ContainerV,
  .right-ContainerV {
    width: 50%;
    height: 100%;
    display: flex;
    z-index: 2;
    flex-direction: column;
    justify-content: center;
  }
  
  .left-ContainerV {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-WrapperV img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
    border: 5px solid;
  }
  
  .image-WrapperV {
    width: 35em;
    height: 15em;
  }
  
  .peces-left {
    position: absolute;
    padding-top: 12rem;
    left:0;
    z-index: 1;
    animation: fish-left 7s linear infinite;
  }

  .peces-left img{
    width: 150px;
  }

  .right-ContainerV {
    padding-top: 60px;
    padding-right: 6rem;
    color: white;
    font-size: 1.2rem;
    justify-content: center;
  }
  
  .misiontasks {
    padding-top: 85px;
    z-index: 2;
    background-image: url("../../imagenes/WaveDivision.png");
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12rem 0 10rem 0;
    
  }
  
  .tasktxt {
    color: var(--deep-marine);
    text-align: center;
  }
  
  .tasktxt h1{
    margin-top: 2rem;
  }

  .imgstask{
    display: flex;
    color:white;
    justify-content: center;
    width:25%;
  }
  
  .task1 img {
    width: 16em;
    height: 16em;
    border-radius: 50%;
    border: 5px solid white;
  }
  
  .task1 p{
    padding-top: 1rem;
    font-size: 1.5rem;
    font-weight: 600;

  }
  
  .task1, .task2, .task3, .task4{
    display: flex;
    flex-direction: column;
    padding: 35px;
    text-align:center;
    align-items: center;
  }

  .task2 img{
    width: 16em;
    height: 16em;
    border-radius: 50%;
    border: 5px solid  white;
  }
  
  .task2 p {
    padding-top: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .task3 img{
    width: 16em;
    height: 16em;
    border-radius: 50%;
    border: 5px solid white;
  }
  
  .task3 p {
    padding-top: 1rem;
    font-weight: 600;
    font-size: 1.5rem;
  }
  
  .task4 img {
    width: 16em;
    height: 16em;
    border-radius: 50%;
    border: 5px solid white;
  }
  .task4 p {
    font-size: 1.5rem;
    font-weight: 600;
    padding-top: 1rem;
  }
  
  
  .infotitle {
    padding-top: 100px;
    text-align: center;
    font-style: italic;
  }
  
  .color {
    color: #48D5D6;
  }
  
  .infodatos,
  .inforight {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .infodatos {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    gap:4rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
  
  .infotxt {
    font-size: 1.5rem;
    padding-top: 60px;
  }
  
  .infodatos {
    color: white;
    font-size: 1.2rem;
    justify-content: center;
    display: flex;
    padding-bottom: 10rem;
  }
  
  .inforight {
    display: flex;
    flex-direction: column;
    gap:30px;
    align-items: center;
  }
  
  .info1 {
    display: flex;
  }
  
  .infoimg1 {
    width: 100px;
    height: 100px;
  }
  
  .info2 {
    display: flex;
  }
  
  .infoimg2 {
    width: 100px;
    height: 100px;
  }
  
  .info3 {
    display: flex;
  }
  
  .infoimg3 {
    width: 100px;
    height: 100px;
  }
    
  .img1,.img2,.img3{
    width: 120px;
    height: 120px;
  }

  .infotxt1, .infotxt2, .infotxt3{
    font-size: 18px;
    padding-top: 7%;
    padding-left: 2.5rem;
  }
  
 
