.mainc{
    padding: 5rem;
    display: flex;
    align-content:center;
    justify-content: space-between;
}
.contact{
    display: flex;
    flex-direction: row;
    padding: 5rem;
    max-width: 100%;
}

.contact-col-left h3{
    font-size: 55px;
    margin-bottom: 1rem;
    font-weight: 800;
}

.contact-col-left p{
    max-width: 425px;
    font-size: 18px;
    list-style-type: 0.3;
}
.contact-col-left{
    display: flex;
    flex-direction: column;
    width:100%;
}
.contact-col-left ul li{
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.contact-col-left ul li img{
    width:35px;
    margin-right: 10px;
}

.contact-col-right form input, .contact-col-right form textarea{
    width: 100%;
    background: rgba(255,255,255,0.5);
    border-radius: 5px;
    outline: 0;
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 15px;
    padding: 1rem;
    color: var(--deep-marine);
    font-family: Arial, Helvetica, sans-serif;
    resize: none;
}
.contact-col-right label{
    font-size: 20px;
}
.btn-submitt{
    width:20%;
    height:45px;
    margin-top: 1rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 15px;
    color: var(--deep-marine);
    background-color: #7BD6CC;
}

.contact-col-right{
    display: flex;
    flex-direction: column;
    width:50%;

}
.contact-col-right span{
    display: block;
    margin: 20px 0;
}

.contact-col-right ::placeholder{
    color: rgba(97,110,110,0.7);
    font-family: Arial, Helvetica, sans-serif;
}