main{
    position:relative;
}
.intro_work{
    position:relative;
    display:flex;
    flex-direction: column;
    margin-top: 100px;  
    padding: 5rem 10rem 0 10rem;
}

.intro-content{
    margin-top: 100px;  
    margin-right: 2rem;
}

.submarine-img{
    width:1100px;
    float: left;
    shape-outside: url(../../imagenes/submarine.png);
    shape-margin: 30px;
}

.intro-content h1{
    padding-top: 2rem;
    padding-right: 2rem;
    line-height: 1.5;
    font-style: italic;
}

.parrafo1{
    margin-top: 40px;
    font-size: 25px;
    margin-right: 100px;
    font-weight: 300;
}

.parrafo2{
    margin-top: 40px;
    font-size: 25px;
    font-weight: 300;
}
.container_bubbles{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    padding-bottom: 100px;
    gap: 40px 60px;
}

.drop{
    position:relative;
    width:375px;
    height:375px;
    background-color: rgba(255,255,255,0.2);
    box-shadow: inset 20px 20px 20px rgba(14, 35, 48, 0.1), 
    25px 35px 20px rgba(14,35,48,0.05), 25px 30px 30px rgba(14,35,48,0.3), 
    inset -20px -20px 25px rgba(255,255,255,0.1);
    transition:0.5s ease-in-out;
    display: flex;
    justify-content: center;
}
.drop:nth-child(1){
    border-radius: 44% 56% 44% 56% / 48% 46% 54% 52% ;
}
.drop:nth-child(2){
    border-radius: 49% 51% 61% 39% / 41% 56% 44% 59% ;
}
.drop:nth-child(3){
    border-radius: 49% 51% 40% 60% / 52% 56% 44% 48% ;
}
.drop:hover{
    border-radius: 50%;
}
.drop::before{
    content:" ";
    position: absolute;
    top:50px;
    left:85px;
    width:35px;
    height:35px;
    background:rgba(255,255,255,0.08);
    border-radius: 50%;
    opacity: 0.9;
}
.drop::after{
    content:" ";
    position: absolute;
    top:90px;
    left:110px;
    width:15px;
    height:15px;
    background:rgba(255,255,255,0.08);
    border-radius: 50%;
    opacity: 0.9;
}

.philosophy-content{
    display: flex;
    flex-direction: column;
    align-items: left;
    align-content: left;
}

.philosophy-text{
    width:700px;
    padding: 1rem 0 1rem 0;
    margin-top: 10rem;
    margin-left: 10rem;
    font-size: 30px;
    font-weight: 300;
    margin-bottom:100px;
}

.invite-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8rem 10rem 15rem 10rem;
    flex-direction: row;
    gap:5rem;
}

.invite-container img{
    border-radius: 20px;
    height:650px;
}
.invite-text{
    vertical-align: middle;
    display: flex;
    flex-direction:column; 
    justify-content: left;
    width:500px;
}
.invite-text h1{
    font-size: 90px;
    line-height:1.2;
}

.invite-text button{
    font-size: 22px;
    margin-top: 3rem;
    width:30%;
    height:60px;
    border-radius: 12px;
    font-weight: bold;
    background-color: var(--menta);
}
.invite-text p{
    font-size: 30px;
    margin-top: 2rem;
    font-weight: 300;
}