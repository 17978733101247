.footer{
    position:absolute;
    bottom:0;
    left: 0;
    width: 100%;
    background-color: #1E717C;
    padding: 10px;
}

.footercont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 15px;
}

.logo-footer-container{
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;

}

.footertext {
    font-size: 1.2rem;
    margin-right: 10px;
    font-weight: 400;
}

.fa {
    font-size: 30px;
    text-align: center;
    text-decoration: none; 
    color: white;
    letter-spacing: 30px;
}

.fa-facebook{
    padding-left: 30px;
}




 