.container-water{
    height:100vh;
    width:100%;
    background-color: rgba(14,36,48, 0.3);
  }

.container-water-text{
    position: absolute;
    top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    padding: 5rem;
}
.quote-home{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.2;
}

.quote-home h2{
    font-size: 60px;
    text-transform: uppercase;
}

.quote-home h1{
    font-size: 100px;
    text-transform: uppercase;
}

.quote-home p{
    margin-top: 1.5rem;
    font-size: 35px;
    font-weight: 300;
}
.container-water-text button{
    margin-top: 4rem;
    height:60px;
    width:142px;
    border-radius: 12px;
    font-weight: bold;
    background-color: #40FFDD;
}